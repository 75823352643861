import React from "react";

interface ScoreMeterProps {
  value: number;
  min?: number;
  max?: number;
  color?: string;
  innerText?: string;
  startAngle?: number;
  endAngle?: number;
  textSize?: string;
}

const ScoreMeter: React.FC<ScoreMeterProps> = ({
  value,
  min = 0,
  max = 15,
  color = "#4FD1C5",
  innerText = value.toString(),
  startAngle = -180,
  endAngle = 0,
  textSize = "text-xl",
}) => {
  const startAngleOffset = -180 - startAngle;
  const endingAngle = (endAngle > 90 ? 90 : endAngle < -90 ? -90 : endAngle) + 90 - startAngleOffset;
  const endingDenominator = (endingAngle / 180) * 2;
  const radius = 50;
  const strokeWidth = 8;
  const circumference = 2 * Math.PI * radius;
  const normalizedValue = (value - min) / (max - min);
  const drawnCircumference = ((startAngle + endAngle) / -360) * circumference;
  const offset = normalizedValue * drawnCircumference;
  return (
    <div className="relative flex h-full w-full items-center justify-center">
      <svg viewBox="0 0 120 120" className="" style={{ width: "100%", height: "100%" }}>
        <circle
          cx="60"
          cy="60"
          r={radius}
          fill="none"
          className="stroke-[#C9C9C9] dark:stroke-[#808080]"
          // stroke={"#808080"}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={circumference * ((endingDenominator + 1) / 4)}
          transform={`rotate(${startAngle}, 60, 60)`}
        />
        <circle
          cx="60"
          cy="60"
          r={radius}
          fill="none"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={circumference - offset}
          transform={`rotate(${startAngle}, 60, 60)`}
        />
      </svg>
      <p
        style={{
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        className={`text-contrast md:${textSize}`}
      >
        {innerText}
      </p>
    </div>
  );
};

export default ScoreMeter;
